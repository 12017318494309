import React from 'react'
import { styled } from "@mui/system"
import { Typography } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const Wraper = styled('div')(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "1280px",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  marginBottom: 95,
  "@media (max-width:576px)": {
    marginBottom: 60,
  },
}))

const WrapperTitle = styled("div")(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20,
  marginBottom: 100,
  "@media (max-width:576px)": {
    marginBottom: 56,
    marginTop: 86,
  },
}))



const WrapperBlock = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: "70px",
  rowGap: "85px",
  alignItems: 'flex-start',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  "@media (max-width:576px)": {
    display: 'flex',
    flexDirection: 'column',
    columnGap: "60px",
  },
}))

const WrapperCard = styled("div")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: "100%",
  boxSizing: "border-box",
  "@media (max-width:1336px)": {
    maxWidth: "100vw",
    padding: "0px 20px 0",
  },
  "@media (max-width:576px)": {
    padding: "0px 40px",
  },
}))

const Timer = styled("div")(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 10,
}))

const City = styled("div")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  marginTop: 30,
}))

const Divider = styled("div")(({ theme }) => ({
  background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
  height: 4,
  width: '100%',
  marginLeft: 10
}))

const QuotesText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Merriweather',
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "24px",
  lineHeight: "190%",
  color: "#222222",
  "@media (max-width:576px)": {
    fontSize: 18,
  },
}))

const ImageContact = styled(GatsbyImage)({
  aspectRatio: 1.354/1,
  borderRadius: 2,
  "@media (max-width:576px)": {
    aspectRatio: 1.359/1,
  },
})

const CityText = styled(Typography)({
  fontSize: 34,
  "@media (max-width:576px)": {
    fontSize: 26,
  },
})


const TextTimer = ({props, time}) => {
  let hour = parseInt(time.split(':')[0])

  if (hour >= 20) {
    return <QuotesText style={{opacity: .4}}>{props.text_for_the_night}</QuotesText>
  }
  if (hour >= 16) {
    return <QuotesText>{props.text_for_the_evening}</QuotesText>
  }
  if (hour >= 12) {
    return <QuotesText>{props.text_for_the_day}</QuotesText>
  }
  if (hour >= 9) {
    return <QuotesText>{props.text_for_the_morning}</QuotesText>
  }
  if (hour < 9) {
    return <QuotesText style={{opacity: .4}}>{props.text_for_the_night}</QuotesText>
  }

  return <QuotesText>time is</QuotesText>
}

function CalcTime({props}) {
  const [seconds, setSeconds] = React.useState(0);

  let d = new Date();
  let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
  let nd = new Date(utc + (3600000*props.GTM));
  let time = nd.toLocaleString([], {hour: 'numeric', hour12: false, minute: 'numeric', second: "numeric"})
  let hour = parseInt(time.split(':')[0])
  let night = (hour >= 20 || hour < 9) ? true : false

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <TextTimer props={props} time={time} />
      <QuotesText style={{opacity: night ? .4 : 1, fontFamily: 'Heiti TC'}}>{time}</QuotesText>
    </>
  )
}

const Card = ({props}) => {
  const image = getImage(props.img.localFile)
  return (
    <WrapperCard>
      <ImageContact image={image} alt={props.city}/>
      <City>
        <CityText variant={'h4'}>
            {props.city}
        </CityText>
        <Divider/>
      </City>
      <Timer>
        <CalcTime props={props}/>     
      </Timer>
    </WrapperCard>
  )
}


export default function Contact({items}) {
  return(
    <Wraper>
      <WrapperTitle>
        <Typography variant='h1' style={{fontSize: 66}}>Contact</Typography>
      </WrapperTitle>
      <WrapperBlock>
        {items.map(item => 
              <Card
                props={item}
              />
        )}
      </WrapperBlock>
    </Wraper>
  )
}
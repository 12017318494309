import React from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Contact from '../components/contact'
import FeedBack from "../components/investorsAndFounders/for-investors/feedback"
import GridLines from '../components/gridLines'
import Seo from "../components/seo"


const ContactPage = ({ data }) => {
  return (
    <Layout headerDark={false}>
      <Seo title="Contact" description={`Venture capital firm focused on early-stage tech companies in enterprise software and consumer technology.`}/>
      <Contact
        items={data.strapiContact['Office']}
      />
      <GridLines
          styles={{
              height: 87,
              heightMob: 30,
              marginBottom: 0,
              marginBottomMob: 0,
              color: "#F8F8F8",
          }}
      />
      <FeedBack/>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query Contact {
    strapiContact {
      Office {
        GTM
        city
        text_for_the_day
        text_for_the_evening
        text_for_the_morning
        text_for_the_night
        img {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: [WEBP, PNG], placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
  }
`